import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import linkedIn from "../images/LI-In-Bug.png"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Tech stuff" />
    <h1>Technical stuff</h1>

    <p>
      As a software developer, there is one thing that is constant, and that is change.
    </p>

    <p>
      Find my LinkedIn profile <a href="https://www.linkedin.com/in/tweissin/">here</a>.
    </p>

    <p>
      I am interested in learning new programming languages such as Go and Rust.
    </p>

    <p>
      I've also dabbled in React.js. In fact this website was written using a framework based on
      React called Gatsby.
    </p>

    <Link to="/dns-tricks/">Stupid DNS Tricks</Link><br />
    <a href="https://medium.com/using-rust-to-rename-files-suitable-for-onedrive/onedrive-as-backup-and-rust-to-the-rescue-3a8a24cffa90">
      Using an application written in Rust to rename files (medium.com)
    </a><br />

    <a href="https://levelup.gitconnected.com/using-rust-to-implement-webdav-sync-502735b27cb0">
      Using Rust to implement WebDav sync (medium.com)
    </a><br />

    <a href="https://github.com/tweissin/rust-aws-lambda">
      An AWS Lambda written in Rust (github.com)
    </a><br />

    <img src={linkedIn} alt="LinkedIn" width="30px" />
    <img src="https://blog.golang.org/go-brand/Go-Logo/PNG/Go-Logo_LightBlue.png" alt="Go" width="60px" />
    <img src="https://www.rust-lang.org/logos/rust-logo-32x32.png" alt="Rust" />

  </Layout>
)

export default IndexPage
